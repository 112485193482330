
import { Button, Layout, Space, Typography} from 'antd';
import QueueAnim from 'rc-queue-anim';
import Typewriter from "typewriter-effect";

// Components
import Logo from '../../../Components/Logo/Logo';

import bg_video from "../../../bg_video.mp4";
import { ExclamationCircleOutlined, ExclamationOutlined, GithubOutlined } from '@ant-design/icons';
  
const Intro = (props) => {

  return (
    <Layout style={{ 
      position: "relative",
      width: "100vw" ,
      height: "100vh",
      overflow: "hidden",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: 0,
      float: "left"
      }}>

      <video style={{objectFit: "cover", height: "100%", width: "100%"}} autoPlay loop muted>
          <source src={bg_video} type='video/mp4' />
      </video>

      <div style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        //backgroundColor: 'rgba(18,217,64,0.4)', /* Use rgba for a semi-transparent overlay */
        backgroundColor: 'rgba(0,0,0,0.4)',
        zIndex: 1060,
        textAlign: "center",
        display: "block",
        margin: "auto"
      }}>
        <Space direction="vertical" style={{display: "flex", height: "100%", justifyContent: "space-around"}}>
          <Logo type="light"/>
          <div>
            
            <Typography.Title level={2} style={{color: "#ffffff"}}>
              Improving  <span className="clickable-text-">resiliency </span>
              of farmers using satellite-based
              <span className="clickable-text"> yield estimation</span>
            </Typography.Title>
            <Space direction='horizontal'>
              <Button className='agci-button tagline-button' type='primary' onClick={props.gettingStarted}>
                <b>Get Started</b>
              </Button>
              <Button icon={<GithubOutlined/>} onClick={() => window.open("https://github.com/Agcurate/Impressyield", "_blank", "noreferrer")}>
                <b>Github</b>
              </Button>
            </Space>
          </div>
          
          <div>
            
            <Typography.Title level={4} style={{color: "#ffffff"}}>
            <Typography.Text  type='warning'><ExclamationOutlined style={{fontSize: 35}}/> </Typography.Text>
       
            A joint research project led by Istanbul Technical University in collaboration with <br /> 
            Agcurate, Middle East Technical University and  OKO Finance.
            </Typography.Title>
          </div>
        </Space>
      </div>
    </Layout>

  );
};

export default Intro;