import fa from './facebook'
import ga from './google'

const Analytics = {
  init: () => {
    console.log('google analytics initializing')
    ga.initialize()
    console.log('facebook analytics initializing')
    fa.initialize()
  },
  trackCropListOpened: () => {
    fa.trackCropListOpened()
    ga.trackCropListOpened()
  },
  trackQuery: (query) => {
    fa.trackQuery(query)
    ga.trackQuery(query)
  },
  trackPDFOpen: (pdf_name) => {
    fa.trackPDFOpen(pdf_name)
    ga.trackPDFOpen(pdf_name)
  }
}

export default Analytics;