import { Card, Col, Drawer, Layout, Row, Space, Statistic, Typography} from 'antd';
import SalesFunnel from './GoalsComponents/SalesFunnel';
import GoalsTimeline from './GoalsComponents/Timeline';
import CountUp from 'react-countup';
import { useState } from 'react';

  
const ScientificOutputs = () => {
  const formatter = (value) => <CountUp end={value} separator="," />;

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const summary = "M. F. Celik, M. S. Isik, G. Taskin, E. Erten and G. Camps-Valls, \"Explainable Artificial Intelligence for Cotton Yield Prediction With Multisource Data,\" in IEEE Geoscience and Remote Sensing Letters, vol. 20, pp. 1-5, 2023, Art no. 8500905, doi: 10.1109/LGRS.2023.3303643.<br /><br />M. S. Isık, M. F. Celik, E. Erten, \"INTERPRETABLE COTTON YIELD PREDICTION MODEL USING EARTH OBSERVATION TIME SERIES,\" IGARSS 2023- 2023 IEEE International Geoscience and Remote Sensing Symposium, 2023, 3442-2445.<br /><br />M. F. Celik, M. S. Isık, E. Erten, G. Camps-Valls, \"EXPLAINABILITY OF END AND MID-SEASON COTTON YIELD PREDICTORS IN CONUS,\" IGARSS 2023- 2023 IEEE International Geoscience and Remote Sensing Symposium, 2023, 3538-3541.<br /><br />M. F. Celik, M. S. Isık, E. Erten, G. Taşkın, \"INFORMATIVE EARTH OBSERVATION VARIABLES FOR COTTON YIELD PREDICTION USING EXPLAINABLE BOOSTING MACHINE,\" IGARSS 2023- 2023 IEEE International Geoscience and Remote Sensing Symposium, 2023, 3542-3545.<br /><br />(Under evaluation): E. Erten, M. S. Işık, M. F. Çelik, \"Can field-scale yield be reconstructed from commune-scale yield using Sentinel-1?\", EUSAR 2024; 15th European Conference on Synthetic Aperture Radar, Munich, Germany, 2024.<br /><br />S. Cetin, B. Ülker, R. G. Cinbis, E. Erten, \"Image to image translation networks for estimating evapotranspiration variations,\" IGARSS 2024- 2024 IEEE International Geoscience and Remote Sensing Symposium, 2024.<br /><br />S. Cetin, B. Ülker, R. B. Cinbis, E. Erten, \"Weekly supervised SAR-driven ET imagery estimation,\" ISPRS Journal of Photogrammetry and Remote Sensing.<br /><br />M. S. Işık, M. F. Çelik, E. Erten, M. Schmit, \"Explainable Time-series Analysis for cotton yield prediction with EO Data,\" Remote Sensing of Environment."

  return (
    <Layout style={{ 
      position: "relative",
      width: "100vw" ,
      overflow: "hidden",
      textAlign: "center",
      backgroundColor: "#c9cbcf",
      padding: 10,
      float: "left",
      boxShadow: 'rgba(143, 168, 191) 0px -15px 15px -15px'
      }}>
        <Layout style={{width: "100%", margin: "0 auto", position: "relative", padding: 10, backgroundColor: "transparent"}}>
        <div style={{ marginBottom: 20, width: "100%", textAlign: "center"}}>
          <Typography.Text style={{fontSize: "5vw", color: "#ffffff", fontWeight: "bold"}}>
            {/*<u style={{boxShadow: "inset 0 -0.5em hsla(134, 85%, 46%, 0.4)"}}></u>*/}
            Stats
          </Typography.Text>
        </div>
      
        <div className="agci-stats">
          <Card 
            className="agci-card-stats"
            bordered={false}
            onClick={showDrawer}
          >
            <Statistic 
              title="Scientific Papers"            
              value={8}
              prefix=""
              formatter={formatter}
            /> 
          </Card>
          <Card 
            className="agci-card-stats"
            bordered={false}
            onClick={showDrawer}
          >
            <Statistic 
              title="Time spent"            
              value={34560}
              formatter={formatter}
              suffix="hours"
            /> 
          </Card>
          <Card 
            className="agci-card-stats"
            bordered={false}
            onClick={showDrawer}
          >
          <Statistic 
            title="Participants" 
            prefix=""
            value={9}
            formatter={formatter}
            suffix="people"
            
          /> 
          </Card>
          <Card 
            className="agci-card-stats"
            bordered={false}
            onClick={showDrawer}
          >
            <Statistic 
            title="Fields Covered"            
            value={50000}
            formatter={formatter}
            prefix="~"
            suffix="ha"
          /> 
          </Card>
          <Card 
            className="agci-card-stats"
            bordered={false}
            onClick={showDrawer}
          >
            <Statistic 
              title="Data processed" 
              value={45000}
              formatter={formatter}
              prefix="~"
              suffix="gb"
            /> 
          </Card>
        </div>
    
      </Layout>
      <Drawer  
          title={"Scientific Outputs"} 
          placement="right" size="large" 
          rootStyle={{width: "100vw", height: "100vh", zIndex: 1500}} 
          onClose={onClose} open={open}
        >
          
          <div dangerouslySetInnerHTML={{__html: summary}}></div>
            
          
        </Drawer>

    </Layout>
  );
};

export default ScientificOutputs;