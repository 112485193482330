
import { HeartOutlined } from '@ant-design/icons';
import { faHandPointLeft, faHandPointRight, faTree } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Divider, Image, Layout, Row, Space, Steps, Typography, message, theme} from 'antd';
import QueueAnim from 'rc-queue-anim';
import TextyAnim from 'rc-texty';
import { useState } from 'react';
import Typewriter from "typewriter-effect";
import Logo from '../../../Components/Logo/Logo';

  
const Footer = () => {


  return (
    <Layout.Footer style={{
      position: "relative",
      width: "100vw",
      overflow: "hidden",
      backgroundColor: "#18191b",
      padding: 10,
      float: "left",
      boxShadow: 'rgba(143, 168, 191) 0px -15px 15px -15px',
    }}>
      <div style={{ display: "flex", flexDirection:"row", flexWrap: "wrap", justifyContent: "space-between"}}>
        <div  style={{width: "20%",  margin: "auto 0", float: 'left'}}>
          <Logo type="light"/>
        </div>
        <div style={{width: "100%", marginBottom: 10}}>
          <Typography.Text style={{color: "#ffffff", float:"left"}}>
            This project was funded by the Climate Change AI Innovation Grants program, hosted by Climate Change AI with the support of the Quadrature Climate Foundation, Schmidt Futures, and the Canada Hub of Future Earth.
          </Typography.Text>
        </div>
      </div>
      
      <Divider />
      <div  className="copyright">
        © 2023 Agcurate. All rights reserved.
        <div className="footer-options">
          <a className="footer-contact" href="mailto:ccai@agcurate.com">ccai [at] agcurate [dot] com</a>
          <ul className="footer-social">
            <li><a href="https://facebook.com/agcurate"><img src="/assets/icons/social/light/facebook.png" /></a></li>
            <li><a href="https://twitter.com/agcurate"><img src="/assets/icons/social/light/twitter.png" /></a></li>
            <li><a href="https://linkedin.com/company/agcurate"><img src="/assets/icons/social/light/linkedin.png" /></a></li>
          </ul>
        </div>
      </div>
    </Layout.Footer>
  );
};

export default Footer;