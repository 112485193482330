import { Card, Drawer, Image, Layout, Space, Typography} from 'antd';
import { forwardRef, useState } from 'react';
  
const Supporters = forwardRef(function _Supporters(props, ref) {
  const [open, setOpen] = useState(false);
  const [supporter, setSupporter] = useState({url: "", title:""}); // to be used in the drawer

  const showDrawer = (supporter) => {
    setOpen(!open);

    switch (supporter) {
      case "itu":
        setSupporter({url: "https://www.itu.edu.tr/", title: "Istanbul Technical University"})
        break;
      case "metu":
        setSupporter({url: "https://metu.edu.tr", title: "Middle East Technical University"})
        break;
      case "agcurate":
        setSupporter({url: "https://agcurate.com", title: "Agcurate"})
        break;
      case "oko":
        setSupporter({url: "https://www.oko.finance/", title: "OKO Finance"})
        break;
      default:
        break;
    }
  };

  const onClose = () => {
    setOpen(false);
    setSupporter({url: "", title: ""})
  };

  return (
    <Layout 
      ref={ref}
      style={{ 
        position: "relative",
        width: "100vw",
        //height: "25vh",
        overflow: "hidden",
        padding: 10,
        float: "left",
        //boxShadow: 'rgba(255,255,255) 0px 15px 15px -15px, rgba(255,255,255) 0px -15px 15px -15px'
        boxShadow: 'inset rgba(143, 168, 191) 0px -15px 15px -15px, inset rgba(143, 168, 191) 0px 15px 15px -15px',
      }}
    >
        <Typography.Text style={{textAlign: "right", color: "#60626C", fontSize: "2.5vw", fontWeight: "bold"}}> 
          Project Participants / Collaborators 
        </Typography.Text>

        <Space direction="horizontal" styles={{item: {width: "25%"}}} style={{marginBottom: 20}}>
          <Card className='supporters-card' hoverable onClick={()=>showDrawer("itu")}>
            <div className='supporters-image'>
              <Image preview={false} style={{objectFit: "contain"}} src="/assets/supporters/itu_logo.png" />
            </div>
          </Card>
          <Card className='supporters-card' hoverable onClick={()=>showDrawer("metu")}>
            <div className='supporters-image'>
              <Image preview={false} style={{objectFit: "contain"}} src="/assets/supporters/metu_logo.jpg" />
            </div>
          </Card> 
          <Card className='supporters-card' hoverable onClick={()=>showDrawer("agcurate")}>
            <div className='supporters-image'>
              <Image preview={false} style={{objectFit: "contain"}} src="/assets/supporters/agcurate_logo.png" />
            </div>
          </Card>
          <Card className='supporters-card' hoverable onClick={()=>showDrawer("oko")}>
            <div className='supporters-image'>
              <Image  preview={false} style={{objectFit: "contain"}} src="/assets/supporters/oko_logo.webp" />
            </div>
          </Card>
        </Space>

        <Drawer 
          className="drawer-full-page" 
          title={supporter.title}
          placement="bottom" 
          rootStyle={{width: "100vw", height: "100vh", zIndex: 1600}} 
          onClose={onClose} 
          size='large' 
          open={open}
        >
          <iframe 
          style={{width: "100%", height: "100%", flex:1}}
          title={supporter.title} 
          frameBorder={0}
          src={supporter.url}></iframe>
        </Drawer>
    </Layout>
  );
});

export default Supporters;