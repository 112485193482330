import { FloatButton, Layout, Space } from 'antd';
import logo from '../../logo.svg';
import './HomeView.css';

import Intro from './Sections/Intro';
import Backstory from './Sections/Backstory';
import Supporters from './Sections/Supporters';
import Traction from './Sections/Traction';
import Tagline from './Sections/Tagline';
import WorkPackages from './Sections/WorkPackages';
import ScientificOutputs from './Sections/ScientificOutputs';
import FundraisingRibbon from './Sections/FundraisingRibbon';
// import Fundraising from './Sections/Fundraising';
import Footer from './Sections/Footer';
import { useRef } from 'react';
import Participants from './Sections/Participants';

function HomeView() {
  const myRef = useRef(null);

  const participants_ref = useRef();
  const backstory_ref = useRef();

  const handleClick = () => {
    console.log("SLAIDFBHK");
    myRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const gettingStarted = (e) => {
    e.preventDefault();
    participants_ref.current?.scrollIntoView({ block: "start",behavior: 'smooth'})
  }

  const continueToBackstory = (e) => {
    e.preventDefault();
    backstory_ref.current?.scrollIntoView({ block: "start",behavior: 'smooth'})
  }


  return (
    <div className="App">
      <div className="App-header">
        <Intro gettingStarted={gettingStarted}/>
        <Supporters ref={participants_ref} /> 
        <Participants continueToBackstory={continueToBackstory}/>
        <Backstory ref={backstory_ref}/>
        <ScientificOutputs />
        <Tagline />
        <WorkPackages />
        {/* <FundraisingRibbon /> */}
        {/* <Traction /> */}
        {/* <Fundraising innerRef={myRef}/> */}
        <Footer />
      </div>
      
      
      <FloatButton.BackTop style={{zIndex: 1500}} />
    </div>
  );
}

export default HomeView;
