import ReactPixel from 'react-facebook-pixel'

const options = {
  autoConfig: true,
  debug: true
}

const FB_Methods =  {
  initialize: () => {
    ReactPixel.init('', options)
    // ReactPixel.init('444294719897891', options) // test account
  },
  trackDeneme: () => {
    ReactPixel.trackCustom('deneme', { value: 'denem' })
  },
  trackCropListOpened: () => {
    ReactPixel.trackCustom('crop_list_opened', { date: Date() })
  },
  trackQuery: (query) => {
    ReactPixel.trackCustom('query_searched', {query: query})
  },
  trackPDFOpen: (pdf_name) => {
    ReactPixel.trackCustom('open_pdf', {pdf_name: pdf_name})
  }
}

export default FB_Methods;