
import { faArrowUpRightFromSquare, faCaretDown, faHandPointLeft, faHandPointRight, faTree } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Drawer, Image, Layout, Space, Typography} from 'antd';
import QueueAnim from 'rc-queue-anim';
import TextyAnim from 'rc-texty';
import { useState } from 'react';
import Typewriter from "typewriter-effect";

  
const Tagline = () => {
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState(false);

  const showDrawer = (url) => {
    setOpen(true);
    setUrl(url);
  };

  const onClose = () => {
    setOpen(false);
  };


  return (
    <Layout style={{ 
      position: "relative",
      width: "100vw" ,
      overflow: "hidden",
      textAlign: "center",
      verticalAlign: "middle",
      display: "table-cell",
      padding: 10,
      zIndex: 1,
      boxShadow: 'rgba(143, 168, 191) 0px 15px 15px -15px'
      }}>

       
      <Typography.Paragraph 
        style={{
          fontSize: "4.5vw", 
          fontWeight: 600, 
          //color: "#3c3f44",
          color: "#18191b",

          display: "initial"}}>
          We make agriculture <u onClick={() => showDrawer("https://blog.agcurate.com/investable-agriculture/")}>investable</u>, <u onClick={() => showDrawer("https://blog.agcurate.com/investable-agriculture/")}>sustainable</u> and <u onClick={() => showDrawer("https://blog.agcurate.com/investable-agriculture/")}>resilient against climate</u>
          
          
          {/* <span style={{fontSize: "20pt"}}>
            <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            </span>
            by empowering field supervisors & agronomists at contract farming organizations with 
          powerful technology tools and protecting the crop production against climate risks by providing satellite-driven risk mitigation instruments.*/}
      </Typography.Paragraph>
      
      <Typography.Paragraph>
        <Button
          className="agci-button tagline-button"
          
          onClick={() => showDrawer("https://blog.agcurate.com/investable-agriculture/")}>
          <Typography.Text style={{fontWeight: 600, color: "#ffffff"}}>  
            SEE HOW
          </Typography.Text>
        </Button>
      </Typography.Paragraph>
      <Drawer className="drawer-full-page" title="Agcurate Blog" placement="bottom" size="large" rootStyle={{width: "100vw", height: "100vh"}} onClose={onClose} open={open}>
        <iframe 
          style={{width: "100%", height: "100%", flex:1}}
          title='Agcurate Blog' 
          frameBorder={0}
          src={url}></iframe>
      </Drawer>
    </Layout>
  );
};

export default Tagline;