
import { Card, Drawer, Image, Layout, Space, Typography} from 'antd';

import { DownCircleOutlined, ExportOutlined, HeartOutlined, UserOutlined } from '@ant-design/icons';
import QueueAnim from 'rc-queue-anim';

import "../HomeView.css";
import { forwardRef, useState } from 'react';

const team = {
  row1: [
    {
      key: 1,
      name: "Esra Erten",
      job: "Prof. Dr. @ ITU",
      brief: {email: "eerten [at] itu[dot]edu[dot]tr", profession: ""},
      avatar: "/assets/founders_images/esraerten.jpeg",
      bio: "Esra Erten received the Ph.D. degree in Computer Vision and Remote Sensing from the Department of Computer Engineering and Microelectronics, Technische Universität Berlin, Berlin, Germany. She was with the High-Frequency Institute, German Aerospace Center (DLR), Oberpfaffenhofen, Germany, from April 2008 to June 2010, where she worked on information theory for multi-channel SAR images. From 2010 to 2012, she was with the Chair of Earth Observation and Remote Sensing, Institute of Environmental Engineering, ETH Zurich, Switzerland, where she worked on applied radar remote sensing for environmental parameter estimation. In 2018 she was a visiting professor at Satellite Applications Catapult, U.K. She was supported by Sabbatical Professorship at The Open University School of Engineering, U.K., during 2018-2020 academic period. Currently, she is a professor at the department of Geomatics Engineering, Faculty of Civil Engineering, Istanbul Technical University, Turkey. She has served as an associate editor for IEEE Geoscience and Remote Sensing Letters and some special issues on remote sensing journals. Her recent research interests include information extraction and image understanding from Earth Observation imagery; in particular uncertainty qualification, multivariate statistics and multidimensional synthetic aperture radar data, with application to agriculture."
    },{
      key: 2,
      name: "Gökberk Cinbiş",
      job: "Assoc. Professor @ METU",
      brief: {email: "gcinbis [at] ceng[dot]metu[dot]edu[dot]tr", profession: ""},
      avatar: "/assets/founders_images/gokberkcinbis.jpeg",
      bio: "Gökberk Cinbiş, Cinpursued his doctoral studies as a member of the LEAR (now THOTH) team at INRIA Grenoble, France, from 2010 to 2014, under the guidance of advisors Jakob Verbeek and Cordelia Schmid, ultimately earning his PhD from Université de Grenoble. Prior to this, he obtained an M.A. degree from Boston University in the United States in 2010, with Stan Sclaroff as his advisor. His research interests primarily encompass machine learning and computer vision, focusing on data-efficient deep learning methods involving minimal supervision such as zero-shot, few-shot, weakly-supervised, and self-supervised learning. Additionally, he is actively engaged in the fields of learning to learn (meta learning), vision and language integration, and large-scale image and video comprehension."
    },{
      key: 3,
      name: "Berk Ülker",
      job: "CAIO @ Agcurate",
      brief: {email: "berk [at] agcurate [dot] com", profession: "Computer Vision & AI Expert, PhD Candidate"},
      avatar: "/assets/founders_images/Berk_bg.png",
      bio: "Berk Ülker graduated from the Middle East Technical University with a degree in Electrical and Electronics Engineering in 2012. From 2012 to 2017, he honed his expertise in image processing software development as a part of the ASELSAN Microelectronics, Guidance, and Electro-Optics Group. He completed his master's studies in 2015, focusing on image processing-based smart transportation solutions, and gained entrepreneurial experience with a team that included Osman Baytaroğlu.<br /><br />In 2017, Berk ventured into the field of semiconductor design and production by joining Nexperia BV in the Netherlands, where he made contributions to the development of image processing software. In 2018, he continued his academic journey by becoming a researcher at Eindhoven University of Technology and embarking on doctoral studies in the field of artificial intelligence. In 2020, he assumed the role of co-founder responsible for artificial intelligence at Agcurate, all while concurrently working towards the completion of his doctoral thesis. Berk has notable publications in international peer-reviewed journals and holds patents in his name."
    },
    
  ],
  row2: [
    {
      key: 1,
      name: "Ahmet Rasim Demirtaş",
      job: "BD Coordinator @ Agcurate",
      brief: {email: "ardemirtas [at] agcurate [dot] com", profession: "Business Development Coordinator, B2B Sales"},
      avatar: "/assets/founders_images/ard.jpeg",
      bio: "Ahmet Rasim Demirtaş brings over five years of experience in business development, project management, and collaboration. He holds responsibility for fostering B2B partnerships, enhancing sales channels, and managing applications for accelerator programs, support, and grant initiatives with a focus on research and development (R&D) and commercialization. Additionally, he plays a vital role in determining data-driven market research and analysis findings essential for defining Agcurate's market entry and penetration strategies.<br /><br />With a background in technology startups aimed at making an impact in fundamental sectors like transportation and agriculture, he has honed his expertise in solving complex issues through holistic thinking. His proficiency extends to formulating clear action plans based on this approach and ensuring their precise execution. Over the years, living both in Turkey and abroad, he has gained invaluable experience collaborating with individuals from diverse cultures and multinational backgrounds.<br /><br />As one of the founding members of the company, Ahmet has played a critical role in Agcurate's bootstrap journey, helping secure a total of $270,000 in grants, alongside Osman, during this period. His work style prioritizes meticulous analysis and planning, often involving team consultations before commencing tasks. When it's time to take action, he values individual autonomy and swift decision-making with minimal guidance while maintaining a goal-focused, practical approach without losing sight of the broader scope of work. Consistency in communication and efficient task execution are his top priorities in team collaboration. He willingly takes on responsibility to coordinate teams and plan tasks, remaining committed to his methodical working style and a continuous eagerness to learn in new work environments and tackle new challenges."
    },{
      key: 2,
      name: "Samet Çetin",
      job: "PhD Candidate @ METU",
      brief: {email: "sametcetin [at] agcurate [dot] com", profession: "Computer Engineer, PhD Candidate"},
      avatar: "/assets/founders_images/sametcetin.jpeg",
      bio: "Samet Çetin completed his undergraduate and master's studies at the Department of Computer Engineering at Middle East Technical University (ODTÜ) in 2019 and 2022, respectively. During his master's program, his research work, which took shape and evolved into his master's thesis, was published as a paper at ICLR, one of the most prestigious international conferences in the field of computer science, in early 2022.<br /><br />In 2022, he also commenced his doctoral studies in the Department of Computer Engineering at ODTÜ, where he continues to engage in research at the doctoral level and work on his thesis. Throughout his master's and doctoral studies, he served as a research scholarship recipient in various research projects funded by TÜBİTAK (The Scientific and Technological Research Council of Turkey). Additionally, he contributed as a data scientist in one of the comprehensive Research and Innovation programs of the European Union, Horizon-2020."
    },{
      key: 3,
      name: "Serkan Işık",
      job: "ITU",
      brief: {email: "", profession: ""},
      avatar: "/assets/founders_images/serkanisik.png",
      bio: ""
    },{
      key: 4,
      name: "Mehmet Furkan Çelik",
      job: "ITU",
      brief: {email: "", profession: ""},
      avatar: <UserOutlined />,
      bio: ""
    },
  ],
  row3: [
    {
      key: 1,
      name: "Osman Baytaroğlu",
      job: "CEO @ Agcurate",
      brief: {email: "osman [at] agcurate [dot] com", profession: "Entrepreneur, Project Manager, B2B Sales"},
      avatar: "/assets/founders_images/Osman_bg.png",
      bio: "Osman Baytaroğlu, an alumnus of Middle East Technical University (METU), graduated with a degree in Electrical and Electronics Engineering in 2012. From his student years onwards, he has been deeply involved in both entrepreneurship and significant international projects. In 2011, he gained his first entrepreneurial experience with the online education platform bedavadersane.com. Between 2011 and 2013, Osman captained the first Turkish university team to participate in the Solar Decathlon, a solar-powered home design and construction competition, conducted in collaboration with the U.S. Department of Energy and the Chinese National Energy Administration.<br /><br />From 2013 to 2020, he assumed project management roles in various national and international projects related to smart transportation and agriculture within different companies. These projects included the development of Turkey's Drought Resistance Map with TARSIM and the Satellite Analysis-Based Declaration Control Project for Cotton in collaboration with the Ministry of Agriculture, among others. He was also involved in international projects such as the Onboard Signaling Module for Hyundai Trams. Since 2020, Osman has served as a co-founder and the General Manager of Agcurate.<br /><br />As a result of his diverse experiences and expertise, Osman has been instrumental in Agcurate's journey and continues to lead the company as it seeks innovative solutions in the fields of smart agriculture and technology."
    },{
      key: 2,
      name: "Metin Emenullahi",
      job: "CPO @ Agcurate",
      brief: {email: "metin [at] agcurate [dot] com", profession: "Entrepreneur, B2B / B2C Product Manager"},
      avatar: "/assets/founders_images/Metin_bg.png",
      bio: "Born in the city of Lankaran, Azerbaijan in 1992\ Metin Emenullahi embarked on his journey as a young entrepreneur. His fascination with computers and electronic products from an early age set him apart. After completing his primary, middle, and high school education in Azerbaijan, he made the move to Ankara in 2008 to pursue his university education. While at the Middle East Technical University (METU), studying Computer Engineering, he seamlessly blended his passion for product development with his entrepreneurial spirit within the confines of the Animation Technologies and Game Development Center (ATOM) at METU Technopark.<br /><br />Metin's career commenced with a focus on web and game technologies. Over the ensuing 15 years, he left his mark on more than 30 software products across diverse sectors, including Information Security Technologies, Consumer Applications, Smart Home, Music, and Agriculture. He also took the lead in numerous national and international research and development projects. Presently, his focus is on leveraging technology to significantly enhance the impact of agriculture globally and create innovative solutions to meet the needs of agricultural stakeholders throughout the growing season. As a member of the community at the European Space Agency's Space Business Incubation Center in the Netherlands, he has represented Agcurate in various programs within the agriculture category and closely witnessed the development of products and technologies in this field.<br /><br />Moreover, through professional engagements with organizations like EARSC, EUSPA, Start-Life, and Google Amsterdam, he maintains active contacts in the field of space technologies and the agriculture sector across the Netherlands, Belgium, the Czech Republic, and Europe as a whole. These connections enable Agcurate to stay at the forefront of the global technology race and make optimal strategic decisions based on the latest information."
    },
  ]
}

const Participants = forwardRef(function _Participants(props, ref) {

  const [selected_member, setSelectedMember] = useState(false);
  const [open, setOpen] = useState(false);

  const showDrawer = (member) => {
    setOpen(true);
    setSelectedMember(member)

  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Layout 
      ref={ref}
      style={{
        position: "relative",
        width: "100vw" ,
        //height: "100vh",
        padding: 10,
        float: "left",
        //boxShadow: 'rgba(143, 168, 191) 0px 15px 15px -15px'
      }}>
        <QueueAnim component="div" delay={100} componentProps={{style: { marginBottom: 20, width: "100%", textAlign: "left"}}}>
          <Typography.Title key={1} level={1} style={{color: "#18191B", fontWeight: "bold"}}>
            Brought to you with <HeartOutlined style={{color: "red"}} /> by 
          </Typography.Title>
        </QueueAnim>
        
        <QueueAnim component={Space} delay={300} componentProps={{className:"founders", wrap: true, style: {flex: 1, justifyContent: "space-evenly", whiteSpace: "wrap"}}} type="scale">
          {
            team.row1.map((member) => (
              <Card 
                key={member.key} 
                hoverable 
                className="founder-card" 
                onClick={() => showDrawer(member)}>

                <Card.Meta
                  avatar={
                  <div className='founder-image'>
                    <Image preview={false}  style={{border: "1px solid #d0fbda", borderRadius: "50%"}} src={member.avatar}/>
                  </div>
                  }
                  title={
                    <Typography.Text  className="clickable-text" style={{ fontSize: "15pt",color: "#18191B", whiteSpace: "wrap"}}>
                      {member.name} &nbsp;
                      <sup className="link-to-something"><ExportOutlined /></sup>
                    </Typography.Text>
                  }
                  description={<Typography.Text>{member.job}</Typography.Text>}
                />
              
              </Card>

            ))
          }
        </QueueAnim>
        <QueueAnim component={Space} delay={300} componentProps={{className:"founders", gutter:16, wrap: true, style: {justifyContent: "space-evenly",}}} type="scale">
        {
            team.row2.map((member) => (
              <Card 
                key={member.key}
                hoverable 
                className="founder-card"
                onClick={() => showDrawer(member)}>

                <Card.Meta
                  avatar={
                  <div className='founder-image'>
                    <Image preview={false} style={{border: "1px solid #d0fbda", borderRadius: "50%"}} src={member.avatar}/>
                  </div>
                  }
                  title={
                    <Typography.Text  className="clickable-text" style={{ fontSize: "15pt",color: "#18191B", whiteSpace: "wrap"}}>
                      {member.name}&nbsp;
                      <sup className="link-to-something"><ExportOutlined /></sup>
                    </Typography.Text>}
                  description={<Typography.Text>{member.job}</Typography.Text>}
                />
              
              </Card>

            ))
          }
        </QueueAnim>
        <QueueAnim component={Space} delay={300} componentProps={{className:"founders", size: "small", gutter:16, wrap: true, style: {justifyContent: "space-evenly", marginBottom: 20}}} type="scale">
        {
            team.row3.map((member) => (
              <Card 
                key={member.key} 
                hoverable 
                className="founder-card"
                onClick={() => showDrawer(member)}>

                <Card.Meta
                  avatar={
                  <div className='founder-image'>
                    <Image preview={false}  style={{border: "1px solid #d0fbda", borderRadius: "50%"}} src={member.avatar}/>
                  </div>
                  }
                  title={
                    <Typography.Text className="clickable-text" style={{ fontSize: "15pt",color: "#18191B",  whiteSpace: "wrap"}}>
                      {member.name} &nbsp;
                      <sup className="link-to-something"><ExportOutlined /></sup>
                    </Typography.Text>}
                  description={<Typography.Text>{member.job}</Typography.Text>}
                />
              
              </Card>

            ))
          }
        </QueueAnim>

  
          <Typography.Text style={{fontSize: 20, textAlign: "center", cursor: "pointer"}}  onClick={props.continueToBackstory}>
            <DownCircleOutlined className="bounce" />
          </Typography.Text>


        <Drawer  
          title={"Project Participant"} 
          placement="right" size="large" 
          rootStyle={{zIndex: 1500}} 
          onClose={onClose} open={open}
        >
          
            <Card 
              key={selected_member.key} 
              bordered={false}
              className="founder-card-drawer"
            >

              <Card.Meta
                avatar={
                <div className='founder-image'>
                  <Image preview={false} style={{border: "1px solid #d0fbda", borderRadius: "50%"}} src={selected_member.avatar}/>
                </div>
                }
                title={<Typography.Text style={{ fontSize: "15pt",color: "#18191B",  whiteSpace: "wrap"}}>{selected_member.name}</Typography.Text>}
                description={
                  <Space direction='vertical'>
                    <Typography.Text>{selected_member.job}</Typography.Text>
                    <Typography.Text>{selected_member.brief?.profession}</Typography.Text>
                    <Typography.Text>{selected_member.brief?.email}</Typography.Text>
                    
                  </Space>
                }
              />
                <Typography.Paragraph style={{fontSize: 17, marginTop: 20, textJustify:"inter-word"}}>
                  <div dangerouslySetInnerHTML={{ __html: selected_member.bio }}></div>
                </Typography.Paragraph>
            </Card>
          
        </Drawer>
    </Layout>

  );
});

export default Participants;